import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect, FieldPhoneNumberInput, FieldLocationAutocompleteInput } from '../../../components';

import css from './ConfirmSignupForm.module.css';
import { CountrySelector } from '../AuthenticationPage';

const ConfirmSignupFormComponent = props => {
    const [countryCode, setCountryCode] = useState({ code: 'US' });
    const [country, setCountry] = useState(null);

    return   (
      <FinalForm
        {...props}
        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress,
            invalid,
            intl,
            termsAndConditions,
            authInfo,
            idp,
            values,
            signupCountries,
            locationName,
            form: formApi,
            currentUserOrigin,
            showAlmostThereMessage
          } = formRenderProps;

          const proximityMaybe = currentUserOrigin && currentUserOrigin.lat && currentUserOrigin.lng
          ? { proximity: [currentUserOrigin.lng, currentUserOrigin.lat] }
          : {}; 
    
          const countryLabel = intl.formatMessage({id:"ConfirmSignupForm.countryFieldLabel"});
    
          // email
          const emailRequired = validators.required(
            intl.formatMessage({
              id: 'ConfirmSignupForm.emailRequired',
            })
          );
          const emailValid = validators.emailFormatValid(
            intl.formatMessage({
              id: 'ConfirmSignupForm.emailInvalid',
            })
          );
    
          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;
    
          // If authInfo is not available we should not show the ConfirmForm
          if (!authInfo) {
            return;
          }
    
          // Initial values from idp provider
          const { email, firstName, lastName } = authInfo;
    
          const addressRequiredMessage = intl.formatMessage({ id: "SignupForm.addressRequired" });
          const addressNotRecognizedMessage = intl.formatMessage({ id: "SignupForm.addressNotRecognized" });
    
          const formSpyChangeHandler = ({ values, dirty }) => {
            if (values && values.country !== country) {
              setCountryCode({ code: values.country });
              setCountry(values.country);
              formApi.batch(() => {
                formApi.change('phoneNumber', null);
              })
            };
          };

          const validatePhoneNumber = validators.composeValidators(
            validators.required(
              intl.formatMessage({ id: "SignupForm.phoneNumberRequired" })
            ),
            validators.phoneNumberValidator(
              intl.formatMessage({ id: "SignupForm.phoneNumberInvalid" }),
              countryCode.code
            )
          );

          const almostThere = showAlmostThereMessage ? (
            <>
              <p className={css.heading}>Almost There!</p>
              <p className={css.subheading}>Create an account or log in,<br /> and we will take you back to the page you came from.</p>
            </>
          ) : null;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <FormSpy onChange={formSpyChangeHandler} subscription={{ values: true, dirty: true }} />
              <div>
                {almostThere}
                <div className={css.noticeBox}>
                  <p>Welcome...Your details are safe with us! <span className={css.grayText}>Contact information will only be shared once a rental is confirmed.</span></p>
                </div>
    
                {/* Name Field */}
                <div className={css.name}>
                  <div className={css.inputBox}>
                    <FieldTextInput
                      className={css.firstNameRoot}
                      type="text"
                      id={formId ? `${formId}.firstName` : 'firstName'}
                      name="firstName"
                      autoComplete="given-name"
                      label={intl.formatMessage({
                        id: 'SignupForm.firstNameLabel',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'SignupForm.firstNamePlaceholder',
                      })}
                      initialValue={firstName}
                      validate={validators.required(
                        intl.formatMessage({
                          id: 'ConfirmSignupForm.firstNameRequired',
                        })
                      )}
                      srOnly={true}
                    />
                  </div>
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lastName` : 'lastName'}
                    name="lastName"
                    autoComplete="family-name"
                    label={intl.formatMessage({
                      id: 'SignupForm.lastNameLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.lastNamePlaceholder',
                    })}
                    initialValue={lastName}
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'ConfirmSignupForm.lastNameRequired',
                      })
                    )}
                    srOnly={true}
                  />
                  <small className={css.note}>Be sure to match name to government ID</small>
                </div>
    
                {/* Location */}
                <FieldLocationAutocompleteInput
                  rootClassName={css.locationAddress}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  name={locationName}
                  label={intl.formatMessage({ id: 'SignupForm.locationLabel' })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.locationPlaceholder',
                  })}
                  useDefaultPredictions={false}
                  format={d => d}
                  valueFromForm={values.location}
                  notShowIcon={true}
                  validate={validators.composeValidators(
                    validators.autocompleteSearchRequired(addressRequiredMessage),
                    validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                  )}
                  srOnly={true}
                  {...proximityMaybe}
                />
    
                {/* Unit Field */}
                <FieldTextInput
                  className={css.unitField}
                  type="text"
                  id={formId ? `${formId}.unit` : 'unit'}
                  name="unit"
                  autoComplete="unit"
                  label={intl.formatMessage({
                    id: 'SignupForm.unitLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.unitPlaceholder',
                  })}
                  srOnly={true}
                />
    
    
                {/* Country Field */}
                <FieldSelect
                  id="country"
                  name="country"
                  className={css.selectCountry}
                  autoComplete="country"
                  label={countryLabel}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.countryRequired',
                    })
                  )}
                  srOnly={true}
                >
                  <option disabled value="">
                    {intl.formatMessage({ id: 'SignupForm.countryPlaceholder' })}
                  </option>
                  {signupCountries.map(c => (
                    <option key={c.code} value={c.code}>
                      {c.country}
                    </option>
                  ))}
                </FieldSelect>
    
                {/* Phone Field */}
                <div className={css.phoneWrapper}>
                  <div className={css.phoneInput}>
                    <CountrySelector
                      options={signupCountries}
                      onSelect={(v) => {
                        setCountryCode({ code: v })
                        formApi.change('phoneNumber', null);
                      }}
                      currentUserCountry={countryCode}
                      rootClassName={css.dropdown}
                    />
    
                    <FieldTextInput
                      className={css.phoneNumber}
                      id={`${formId}.phoneNumber`}
                      name="phoneNumber"
                      label={intl.formatMessage({
                        id: 'SignupForm.phoneLabel',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'SignupForm.phonePlaceholder',
                      })}
                      validate={validatePhoneNumber}
                      srOnly={true}
                    />
                  </div>
                  <small className={css.note}>Will only contact for support or rental notifications</small>
                </div>
    
                {/* Email */}
                <div className={css.email}>
                  <FieldTextInput
                    type="email"
                    id={formId ? `${formId}.email` : 'email'}
                    name="email"
                    autoComplete="email"
                    label={intl.formatMessage({
                      id: 'ConfirmSignupForm.emailLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'ConfirmSignupForm.emailPlaceholder',
                    })}
                    initialValue={email}
                    validate={validators.composeValidators(emailRequired, emailValid)}
                    srOnly={true}
                  />
                  <small className={css.note}>We will email booking information and messages</small>
                </div>
    
              </div>
    
              <div className={css.bottomWrapper}>
                {termsAndConditions}
                <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                  <FormattedMessage id="ConfirmSignupForm.signUp" values={{ idp: idp }} />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
};

ConfirmSignupFormComponent.defaultProps = { inProgress: false };

ConfirmSignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ConfirmSignupForm = compose(injectIntl)(ConfirmSignupFormComponent);
ConfirmSignupForm.displayName = 'ConfirmSignupForm';

export default ConfirmSignupForm;
