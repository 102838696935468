import React, { useState, useEffect } from 'react';
import { bool, func, object, oneOf, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import CountryFlag from 'react-world-flags';
import swal from 'sweetalert2';
 

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { camelize } from '../../util/string';
import { pathByRouteName } from '../../util/routes';
import { apiBaseUrl } from '../../util/api';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';

import { login, authenticationInProgress, signup, signupWithIdp, clearErrors } from '../../ducks/auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';

import {
  Page,
  Heading,
  NamedRedirect,
  LinkTabNavHorizontal,
  SocialLoginButton,
  ResponsiveBackgroundImageContainer,
  Modal,
  LayoutSingleColumn,
  OutsideClickHandler,
  IconArrowHead,
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';

import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import ConfirmSignupForm from './ConfirmSignupForm/ConfirmSignupForm';
import LoginForm from './LoginForm/LoginForm';
import SignupForm from './SignupForm/SignupForm';
import EmailVerificationInfo from './EmailVerificationInfo';

// We need to get ToS asset and get it rendered for the modal on this page.
import { TermsOfServiceContent } from '../../containers/TermsOfServicePage/TermsOfServicePage';

// We need to get PrivacyPolicy asset and get it rendered for the modal on this page.
import { PrivacyPolicyContent } from '../../containers/PrivacyPolicyPage/PrivacyPolicyPage';

import { TOS_ASSET_NAME, PRIVACY_POLICY_ASSET_NAME } from './AuthenticationPage.duck';

import { FacebookLogo, GoogleLogo } from './socialLoginLogos';
import MobileBottomNavbar from '../../components/MobileBottomNavbar/MobileBottomNavbar';
import css from './AuthenticationPage.module.css';

export const CountrySelector = ({
  options,
  onSelect,
  currentUserCountry,
  rootClassName,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const updatedOptions = options.reduce((acc, option) => {
    if (option.code === currentUserCountry) {
      acc.unshift(option);
    } else {
      acc.push(option);
    }
    return acc;
  }, []);

  const handleOptionClick = option => {
    const { code } = option || {};
    setIsDropdownOpen(false);
    onSelect(code);
  };

  const classes = rootClassName
    ? `${rootClassName}`
    : css.dropdown;

  return (
    <div className={classes}>
      <OutsideClickHandler onOutsideClick={() => setIsDropdownOpen(false)}>
        <div
          className={classNames(
            css.selectedOption,
            isDropdownOpen ? css.selectedOptionOpen : css.selectedOptionClose
          )}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <div className={css.userCurrency}>
            <span>
              <CountryFlag
                code={currentUserCountry.code}
                height="20"
                width="20"
              />
            </span>
            <span>{currentUserCountry.code}</span>
          </div>
          <IconArrowHead
            direction="down"
            size="small"
            rootClassName={css.arrowIcon}
          />
        </div>
        {isDropdownOpen && (
          <div className={css.optionsContainer}>
            {updatedOptions.map(option => (
              <div
                key={option.code}
                className={classNames(
                  currentUserCountry.code == option.code && css.activeOptions,
                  css.option
                )}
                onClick={() =>
                  handleOptionClick({
                    code: option.code,
                  })
                }
              >
                <span>
                  <CountryFlag
                    code={option.code}
                    height="20"
                    width="20"
                  />
                </span>
                <span>{option.code}</span>
              </div>
            ))}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

// Social login buttons are needed by AuthenticationForms
export const SocialLoginButtonsMaybe = props => {
  const routeConfiguration = useRouteConfiguration();
  const { isLogin, showFacebookLogin, showGoogleLogin, from } = props;
  const showSocialLogins = showFacebookLogin || showGoogleLogin;

  const getDefaultRoutes = () => {
    const baseUrl = apiBaseUrl();

    // Route where the user should be returned after authentication
    // This is used e.g. with EditListingPage and ListingPage
    const fromParam = from ? `from=${from}` : '';

    // Default route where user is returned after successfull authentication
    const defaultReturn = pathByRouteName('LandingPage', routeConfiguration);
    const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

    // Route for confirming user data before creating a new user
    const defaultConfirm = pathByRouteName('ConfirmPage', routeConfiguration);
    const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

    return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
  };

  const authWithFacebook = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  const authWithGoogle = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  return showSocialLogins ? (
    <div className={css.idpButtons}>
      <div className={css.socialButtonsOr}>
        <span className={css.socialButtonsOrText}>
          <FormattedMessage id="AuthenticationPage.or" />
        </span>
      </div>

      {showFacebookLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={() => authWithFacebook()}>
            <span className={css.buttonIcon}>{FacebookLogo}</span>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
            )}
          </SocialLoginButton>
        </div>
      ) : null}

      {showGoogleLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={() => authWithGoogle()}>
            <span className={css.buttonIcon}>{GoogleLogo}</span>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
            )}
          </SocialLoginButton>
        </div>
      ) : null}
    </div>
  ) : null;
};

// Tabs for SignupForm and LoginForm
export const AuthenticationForms = props => {
  const {
    isLogin,
    showFacebookLogin,
    showGoogleLogin,
    from,
    submitLogin,
    loginError,
    idpAuthError,
    signupError,
    authInProgress,
    submitSignup,
    termsAndConditions,
    currentUserLocationInfo,
    config,
    locationName,
    intl,
    onClearErrors,
    showAlmostThereMessage
  } = props;

  const { signupCountries } = config;

  const fromState = { state: from ? { from } : null };
  const tabs = [
    {
      text: (
        <Heading as={!isLogin ? 'h1' : 'h2'} rootClassName={css.tab}>
          <FormattedMessage id="AuthenticationPage.signupLinkText" />
        </Heading>
      ),
      selected: !isLogin,
      linkProps: {
        name: 'SignupPage',
        to: fromState,
      },
    },
    {
      text: (
        <Heading as={isLogin ? 'h1' : 'h2'} rootClassName={css.tab}>
          <FormattedMessage id="AuthenticationPage.loginLinkText" />
        </Heading>
      ),
      selected: isLogin,
      linkProps: {
        name: 'LoginPage',
        to: fromState,
      },
    },
  ];

  const { address, country, city, origin } = currentUserLocationInfo || {};

  const locationMaybe = !!address ? {
        location: {
          search: address,
          selectedPlace: { address, city, origin },
      }
  } : {};

  const signupFormInitials = {
    // ...locationMaybe,
    country: !!signupCountries.find(c => c.code === country) ? country : null,
  };

  const [initialValues, setInitialValues] = useState(signupFormInitials);

  const handleSubmitSignup = values => {
    const { fname, lname, ...rest } = values;
    const params = { firstName: fname.trim(), lastName: lname.trim(), location: values[locationName], ...rest };
    submitSignup(params);
    setInitialValues(values);
  };

  const loginErrorMessage = (
    intl.formatMessage({ id: "AuthenticationPage.loginFailed" })
  );

  const idpAuthErrorMessage = (
    intl.formatMessage({
      id: "AuthenticationPage.idpAuthFailed"
    })
  );

  const signupErrorMessage = (
    isSignupEmailTakenError(signupError) ? (
      intl.formatMessage({
        id: "AuthenticationPage.signupFailedEmailAlreadyTaken"
      })
    ) :
      intl.formatMessage({
        id: "AuthenticationPage.signupFailed"
      })
  );

  const loginOrSignupError =
    isLogin && !!idpAuthError
      ? idpAuthErrorMessage
      : isLogin && !!loginError
      ? loginErrorMessage
      : !!signupError
      ? signupErrorMessage
      : null;

  // Error popup
  useEffect(() => {
    if (loginOrSignupError && loginOrSignupError !== "") {
      swal.fire({
        title: "",
        text: loginOrSignupError,
        customClass: {
          htmlContainer: css.error,
          confirmButton: css.errorButton
        }
      }).then(() => onClearErrors());
    }
  }, [loginOrSignupError]);

  return (
    <div className={css.content}>
      <LinkTabNavHorizontal className={css.tabs} tabs={tabs} />
      {isLogin ? (
        <LoginForm className={css.loginForm} onSubmit={submitLogin} inProgress={authInProgress} showAlmostThereMessage={showAlmostThereMessage} />
      ) : (
          <SignupForm
            className={css.signupForm}
            onSubmit={handleSubmitSignup}
            inProgress={authInProgress}
            termsAndConditions={termsAndConditions}
            initialValues={signupFormInitials}
            signupCountries={signupCountries}
            locationName={locationName}
            currentUserOrigin={origin}
            showAlmostThereMessage={showAlmostThereMessage}
          />
      )}

      <SocialLoginButtonsMaybe
        isLogin={isLogin}
        showFacebookLogin={false}
        showGoogleLogin={showGoogleLogin}
        from={from}
      />
    </div>
  );
};

// Form for confirming information from IdP (e.g. Facebook)
// This is shown before new user is created to Marketplace API
const ConfirmIdProviderInfoForm = props => {
  const {
    authInfo,
    authInProgress,
    confirmError,
    submitSingupWithIdp,
    termsAndConditions,
    currentUserLocationInfo,
    config,
    locationName,
    intl,
    onClearErrors,
    showAlmostThereMessage
  } = props;
  
  const { signupCountries } = config;

  const idp = authInfo ? authInfo.idpId.replace(/^./, str => str.toUpperCase()) : null;

  const { address, country, city, origin } = currentUserLocationInfo || {};
  const locationMaybe = !!address ? {
        location: {
          search: address,
          selectedPlace: { address, city, origin },
      }
  } : {};

  const signupFormInitials = {
    // ...locationMaybe,
    country: !!signupCountries.find(c => c.code === country) ? country : null,
  };

  const [initialValues, setInitialValues] = useState(signupFormInitials);

  const handleSubmitConfirm = values => {
    const { idpToken, email, firstName, lastName, idpId } = authInfo;
    const {
      email: newEmail,
      firstName: newFirstName,
      lastName: newLastName,
      country,
      storeName,
      unit,
      ...rest
    } = values;

    const {
      selectedPlace: { address, city, origin, postcode },
    } = values[locationName];

    const addressMaybe = !!address ? { address } : {};
    const cityMaybe = !!city ? { city } : {};
    const originMaybe = !!origin
      ? { origin: { lat: origin.lat, lng: origin.lng } }
      : {};
    const postcodeMaybe = !!postcode ? { postcode } : {};
    const storeNameMaybe = !!storeName?.trim() ? { storeName } : {};
    const unitMaybe = !!unit?.trim() ? { unit } : {};

    // Pass email, fistName or lastName to Marketplace API only if user has edited them
    // sand they can't be fetched directly from idp provider (e.g. Facebook)

    const authParams = {
      ...(newEmail !== email && { email: newEmail }),
      ...(newFirstName !== firstName && { firstName: newFirstName }),
      ...(newLastName !== lastName && { lastName: newLastName }),
    };

    const publicData = {
      country,
      ...addressMaybe,
      ...cityMaybe,
      ...originMaybe,
      ...postcodeMaybe,
      ...storeNameMaybe,
      ...unitMaybe,
      permitted:false
    };
    // If the confirm form has any additional values, pass them forward as user's protected data
    const protectedData = !isEmpty(rest) ? { ...rest } : null;

    submitSingupWithIdp({
      idpToken,
      idpId,
      ...authParams,
      ...(!!protectedData && { protectedData }),
      publicData,
    });
    setInitialValues(values);
  };

  const confirmErrorMessage = confirmError
    ? (isSignupEmailTakenError(confirmError) ?
      intl.formatMessage({
        id: "AuthenticationPage.signupFailedEmailAlreadyTaken"
      }) : intl.formatMessage({
        id: "AuthenticationPage.signupFailed"
      }))
    : null
    

  useEffect(() => {
    if (confirmErrorMessage && confirmErrorMessage !== "") {
      swal.fire({
        title: "",
        text: confirmErrorMessage,
        customClass: {
          htmlContainer: css.error,
          confirmButton: css.errorButton
        }
      }).then(() => onClearErrors());
    }
  }, [confirmErrorMessage]);

  return (
    <div className={css.content}>
      <Heading as="h1" rootClassName={css.signupWithIdpTitle}>
        <FormattedMessage
          id="AuthenticationPage.confirmSignupWithIdpTitle"
          values={{ idp }}
        />
      </Heading>

      <p className={css.confirmInfoText}>
        <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
      </p>
      <ConfirmSignupForm
        className={css.form}
        onSubmit={handleSubmitConfirm}
        inProgress={authInProgress}
        termsAndConditions={termsAndConditions}
        authInfo={authInfo}
        idp={idp}
        initialValues={initialValues}
        signupCountries={signupCountries}
        locationName={locationName}
        currentUserOrigin={origin}
        showAlmostThereMessage={showAlmostThereMessage}
      />
    </div>
  );
};

export const AuthenticationOrConfirmInfoForm = props => {
  const {
    tab,
    authInfo,
    from,
    showFacebookLogin,
    showGoogleLogin,
    submitLogin,
    submitSignup,
    submitSingupWithIdp,
    authInProgress,
    loginError,
    idpAuthError,
    signupError,
    confirmError,
    termsAndConditions,
    currentUserLocationInfo,
    config,
    intl,
    onClearErrors
  } = props;
  const isConfirm = tab === 'confirm';
  const isLogin = tab === 'login';
  const showAlmostThereMessage = !!from;

  // To restrict browser from suggesting auto-complete in location input
  // field, we need to ensure the name is different each time.
  const locationName = uuidv4();

  return isConfirm ? (
    <ConfirmIdProviderInfoForm
      authInfo={authInfo}
      submitSingupWithIdp={submitSingupWithIdp}
      authInProgress={authInProgress}
      confirmError={confirmError}
      termsAndConditions={termsAndConditions}
      currentUserLocationInfo={currentUserLocationInfo}
      config={config}
      locationName={locationName}
      intl={intl}
      onClearErrors={onClearErrors}
      showAlmostThereMessage={showAlmostThereMessage}
    />
  ) : (
    <AuthenticationForms
      isLogin={isLogin}
      showFacebookLogin={showFacebookLogin}
      showGoogleLogin={showGoogleLogin}
      from={from}
      loginError={loginError}
      idpAuthError={idpAuthError}
      signupError={signupError}
      submitLogin={submitLogin}
      authInProgress={authInProgress}
      submitSignup={submitSignup}
      termsAndConditions={termsAndConditions}
      currentUserLocationInfo={currentUserLocationInfo}
      config={config}
      locationName={locationName}
      intl={intl}
      onClearErrors={onClearErrors}
      showAlmostThereMessage={showAlmostThereMessage}
    ></AuthenticationForms>
  );
};

const getAuthInfoFromCookies = () => {
  return Cookies.get('st-authinfo')
    ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
    : null;
};
const getAuthErrorFromCookies = () => {
  return Cookies.get('st-autherror')
    ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
    : null;
};

export const AuthenticationPageComponent = props => {
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [authInfo, setAuthInfo] = useState(getAuthInfoFromCookies());
  const [authError, setAuthError] = useState(getAuthErrorFromCookies());
  const config = useConfiguration();

  useEffect(() => {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    if (authError) {
      Cookies.remove('st-autherror');
    }
  }, []);

  // On mobile, it's better to scroll to top.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tosModalOpen, privacyModalOpen]);

  const {
    authInProgress,
    currentUser,
    intl,
    isAuthenticated,
    location,
    loginError,
    scrollingDisabled,
    signupError,
    submitLogin,
    submitSignup,
    confirmError,
    submitSingupWithIdp,
    tab,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onManageDisableScrolling,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
    currentUserLocationInfo,
    onClearErrors
  } = props;

  // History API has potentially state tied to this route
  // We have used that state to store previous URL ("from"),
  // so that use can be redirected back to that page after authentication.
  const locationFrom = location.state?.from || null;
  const authinfoFrom = authInfo?.from || null;
  const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  const isLogin = tab === 'login';

  // We only want to show the email verification dialog in the signup
  // tab if the user isn't being redirected somewhere else
  // (i.e. `from` is present). We must also check the `emailVerified`
  // flag only when the current user is fully loaded.
  const showEmailVerification =
    !isLogin && currentUserLoaded && !user.attributes.emailVerified;

  // Already authenticated, redirect away from auth page
  if (isAuthenticated && from) {
    return <Redirect to={from} />;
  } else if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
    return <NamedRedirect name="LandingPage" />;
  }

  const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
    sendVerificationEmailError
  )
    ? 'AuthenticationPage.resendFailedTooManyRequests'
    : 'AuthenticationPage.resendFailed';
  const resendErrorMessage = sendVerificationEmailError ? (
    <p className={css.error}>
      <FormattedMessage id={resendErrorTranslationId} />
    </p>
  ) : null;

  const marketplaceName = config.marketplaceName;
  const schemaTitle = isLogin
    ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { marketplaceName })
    : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { marketplaceName });
  const schemaDescription = isLogin
    ? intl.formatMessage({ id: 'AuthenticationPage.schemaDescriptionLogin' }, { marketplaceName })
    : intl.formatMessage({ id: 'AuthenticationPage.schemaDescriptionSignup' }, { marketplaceName });

  const topbarClasses = classNames({
    [css.hideOnMobile]: showEmailVerification,
  });

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
        description: schemaDescription,
      }}
    >
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        mobileBottomNavbar={<MobileBottomNavbar/>}
        topbar={<TopbarContainer className={topbarClasses} />}
        footer={<FooterContainer />}
      >
        <ResponsiveBackgroundImageContainer
          className={css.root}
          childrenWrapperClassName={css.contentContainer}
          as="section"
          image={config.branding.brandImage}
          sizes="100%"
          useOverlay
        >
          {showEmailVerification ? (
            <EmailVerificationInfo
              name={user.attributes.profile.firstName}
              email={<span className={css.email}>{user.attributes.email}</span>}
              onResendVerificationEmail={onResendVerificationEmail}
              resendErrorMessage={resendErrorMessage}
              sendVerificationEmailInProgress={sendVerificationEmailInProgress}
            />
          ) : (
            <AuthenticationOrConfirmInfoForm
              tab={tab}
              authInfo={authInfo}
              from={from}
              showFacebookLogin={!!process.env.REACT_APP_FACEBOOK_APP_ID}
              showGoogleLogin={!!process.env.REACT_APP_GOOGLE_CLIENT_ID}
              submitLogin={submitLogin}
              submitSignup={submitSignup}
              submitSingupWithIdp={submitSingupWithIdp}
              authInProgress={authInProgress}
              loginError={loginError}
              idpAuthError={authError}
              signupError={signupError}
              confirmError={confirmError}
              currentUserLocationInfo={currentUserLocationInfo}
              termsAndConditions={
                <TermsAndConditions
                  onOpenTermsOfService={() => setTosModalOpen(true)}
                  onOpenPrivacyPolicy={() => setPrivacyModalOpen(true)}
                  intl={intl}
                />
              }
              config={config}
              intl={intl}
              onClearErrors={onClearErrors}
            />
          )}
        </ResponsiveBackgroundImageContainer>
      </LayoutSingleColumn>
      <Modal
        id="AuthenticationPage.tos"
        isOpen={tosModalOpen}
        onClose={() => setTosModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.termsWrapper}>
          <TermsOfServiceContent
            inProgress={tosFetchInProgress}
            error={tosFetchError}
            data={tosAssetsData?.[camelize(TOS_ASSET_NAME)]?.data}
          />
        </div>
      </Modal>
      <Modal
        id="AuthenticationPage.privacyPolicy"
        isOpen={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.privacyWrapper}>
          <PrivacyPolicyContent
            inProgress={tosFetchInProgress}
            error={tosFetchError}
            data={tosAssetsData?.[camelize(PRIVACY_POLICY_ASSET_NAME)]?.data}
          />
        </div>
      </Modal>
    </Page>
  );
};

AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  confirmError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
  showSocialLoginsForTests: false,
  privacyAssetsData: null,
  privacyFetchInProgress: false,
  privacyFetchError: null,
  tosAssetsData: null,
  tosFetchInProgress: false,
  tosFetchError: null,
};

AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  confirmError: propTypes.error,

  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup', 'confirm']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // to fetch privacy-policy page asset
  // which is shown in modal
  privacyAssetsData: object,
  privacyFetchInProgress: bool,
  privacyFetchError: propTypes.error,

  // to fetch terms-of-service page asset
  // which is shown in modal
  tosAssetsData: object,
  tosFetchInProgress: bool,
  tosFetchError: propTypes.error,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError, currentUserLocationInfo } = state.user;
  const {
    pageAssetsData: privacyAssetsData,
    inProgress: privacyFetchInProgress,
    error: privacyFetchError,
  } = state.hostedAssets || {};
  const { pageAssetsData: tosAssetsData, inProgress: tosFetchInProgress, error: tosFetchError } =
    state.hostedAssets || {};

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    privacyAssetsData,
    privacyFetchInProgress,
    privacyFetchError,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
    currentUserLocationInfo,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onClearErrors: () => dispatch(clearErrors()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
